import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { useDispatch } from "react-redux";
import {
  getBench,
  updateSingleResourceBench,
} from "../../../../redux/features/bench/bench.feature";
import "./BenchDeleteConfirmationModal.css";

const BenchDeleteConfirmationModal = ({
  benchDeleteModal,
  setBenchDeleteModal,
  setHasReserved,
  singleResource,
  hasReserved,
}) => {
  const dispatch = useDispatch();
  const deleteHandler = () => {
    console.log(singleResource);
    const BenchReservedstatus = "Bench";
    const newObject = {
      name: singleResource[0].name,
      totalWorkExp: singleResource[0].totalWorkExp,
      totalExpinFission: singleResource[0].totalExpinFission,
      primarySkills: singleResource[0].primarySkills,
      status: BenchReservedstatus,
      _id: singleResource[0]._id,
    };
    // console.log(newObject);
    dispatch(updateSingleResourceBench(newObject));
    dispatch(getBench());
    setHasReserved(!hasReserved);
    setBenchDeleteModal(false);
  };
  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={benchDeleteModal}
        className="modal"
      >
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="modalHeader">
                Remove From Bench Reserved
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => {
                  setBenchDeleteModal(false);
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Are you sure you want to undo this?</p>
            </MDBModalBody>
            <MDBModalFooter>
            <MDBBtn onClick={deleteHandler}>Yes</MDBBtn>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setBenchDeleteModal(false);
                }}
              >
                No
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default BenchDeleteConfirmationModal;
