export const POC_TABLE_HEADERS = [
  { label: "Sl Number", value: "serialNumber" },
  { label: "Name", value: "name" },
  { label: "Status", value: "status" },
  { label: "Duration", value: "duration", metric: "Days" },
  { label: "CreatedBy", value: "createdBy" },
  { label: "Members", value: "members" },
  { label: "Documents", value: "documents" },
  { label: "Actions", value: "actions" },
];
