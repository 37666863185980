import React from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import { toast, ToastContainer } from "react-toastify";


let Navbar = () => {
  const role = localStorage.getItem("role");
  const location = useLocation();  
  const pathname = location.pathname;
  tippy("#home", {
    content: "HOME",
    placement: "bottom",
    // arrow: "narrow",
    animation: "rubberBand",
    theme: "material",
    interactive: true,
  });
  tippy("#bench", {
    content: "BENCH",
    placement: "bottom",
    // arrow: "narrow",

    animation: "rubberBand",
    theme: "material",
    interactive: true,
  });
  tippy("#poc", {
    content: "POC",
    placement: "bottom",
    // arrow: "narrow",

    animation: "rubberBand",
    theme: "material",
    interactive: true,
  });
  tippy("#logOut", {
    content: "LOG OUT",
    placement: "bottom",
    // arrow: "narrow",

    animation: "rubberBand",
    theme: "material",
    interactive: true,
  });
  const logOutHandler = async () => {
    await localStorage.clear()
      localStorage.removeItem("access_token");
      localStorage.removeItem("role");
      localStorage.removeItem("resourceID")
    
    toast.success("Account Logged Out", { autoClose: 1500 });
  };

  return (
    <React.Fragment>
      {/* sticky-top */}
      <nav className="navbar navbar-dark bg-primary navbar-expand-sm">
        <div className="container">
          <div className="collapse navbar-collapse">
           {role === "admin" && 
              <ul className="navbar-nav me-auto mb-1 ">
              <li className="nav-item icon NavbarNavItem">
                <Link to="/dashboard" className="nav-link">
                  <i id="home" className={`fas fa-home ${pathname==='/dashboard'?'icon-active':''}`} />
                </Link>
            </li>

              <li className="nav-item icon NavbarNavItem">
                <Link to="/benchlist" className="nav-link">
                  <i id="bench" className={`fas fa-users ${pathname==='/benchlist'?'icon-active':''}` } />
                </Link>
              </li>
              <li className="nav-item icon NavbarNavItem">
                <Link to="/poc" className="nav-link">
                  <i id="poc" className={`fas fa-file ${pathname==='/poc'?'icon-active':''}` } />
                </Link>
              </li>
              {/* <ul className="navbar navbar-nav ml-auto"> */}
            </ul>
           } 
            <li className="nav-item icon NavbarLogOut">
              <Link to="/" className="nav-link">
                <i
                  id="logOut"
                  className="fas fa-sign-out-alt "
                  onClick={logOutHandler}
                />
              </Link>
            </li>

            {/* </ul> */}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
