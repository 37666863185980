import Axios from "axios";
import API from "../../api";

const pocCount = () => {
  return API.get(`/dashboard/poc/count`);
};

const resourceActive = () => {
  return API.get(`/dashboard/resource/active`);
};
const resourceCount = () => {
  return API.get(`/dashboard/resource/count`);
};
const allDashBoardPocs = () => {
  return API.get(`/poc`);
};

const inActiveResources = () => {
  return API.get(`/dashboard/resource/inactive`);
};
// const pieChartData = () => {
//   return API.get(`/dashboard/poc/count`);
// };

//  Cards API Bench  

const ideaPoc = () => {
  return API.get(`/dashboard/poc/idea`);
};
const activePoc = () => {
  return API.get(`/dashboard/poc/Active`);
};
const holdPoc = () => {
  return API.get(`/dashboard/poc/Hold`);
};
const closedPoc = () => {
  return API.get(`/dashboard/poc/closed`);
};

//  Cards API Bench  
const benchReservedPoc = () => {
  return API.get(`/dashboard/resource/BenchReserved`);
};
const benchPoc = () => {
  return API.get(`/dashboard/resource/Bench`);
};
const benchFiveMonthsPoc = () => {
  return API.get(`/dashboard/resource/5months`);
};

const missedWorklogs = () => {
  return API.get(`/missing/worklogs`);
};

const dashboardServices = {
  pocCount,
  resourceActive,
  resourceCount,
  allDashBoardPocs,
  inActiveResources,
activePoc,
holdPoc,
closedPoc,
benchReservedPoc,
benchPoc,
benchFiveMonthsPoc,
ideaPoc,
missedWorklogs
};
export default dashboardServices;
