import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBTextArea,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import "./newbenchEmployee.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createBench,
  getBench,
  getBenchId,
  updateBench,
} from "../../../redux/features/bench/bench.feature";
import BenchServices from "../../../redux/features/bench/benchServices";
import Axios from "axios";
import { toast } from "react-toastify";
let NewBenchEmployee = () => {
  let BASE_URL = `http://brm-tool.ap-south-1.elasticbeanstalk.com/resources`;
  let { id } = useParams();
  // console.log(id)
  let { benchListItem } = useSelector((store) => {
    return store["bench"];
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statushasError, setStatusHasError] = useState(false);

  let [newBench, setNewBench] = useState({
    // id:null,
    name: "",
    email: "",
    emp_id: "",
    password: "",
    totalWorkExp: null ? totalWorkExp.toString() : "",
    totalExpinFission: null ? totalExpinFission.toString() : "",
    primarySkills: [],
    reportingManager: "",
    projectName: "",
    teamLead: "",
    status: "",
    notes: "",
    bench_start_date: "",
    reporting_manager_email: "",
    // spreadsheetId: "",
    role: "",
  });

  let handleUpdateInput = (event) => {
    // console.log(event.target)
    //  console.log(newBench)
    //  console.log({[event.target.name]:event.target.value})
    //  newBench[event.target.name]=event.target.value
    let value = "";
    // if (
    //   event.target.name === "totalWorkExp" ||
    //   event.target.name === "totalExpinFission"
    // )
    //   value = '';

    switch (event.target.name) {
      case "projectName":
      case "teamLead":
      case "reportingManager":
      case "name":
        {
          if (/^[a-zA-Z ]*$/.test(event.target.value)) {
            value = event.target.value;
          }
        }
        break;
      case "totalExpinFission":
      case "totalWorkExp":
        {
          if (/^[0-9.]+$/.test(event.target.value)) {
            value = event.target.value;
          }
        }
        break;
      case "emp_id":
      case "password":
      case "status":
      case "notes":
      case "bench_start_date":
      case "reporting_manager_email":
      case "role":
      case "email": {
        value = event.target.value;
      }

      default:
        break;
    }
    setNewBench({
      ...newBench,
      [event.target.name]: value,
      // newBench[event.target.name]=event.target.value
    });
  };

  const [statusError, setStatusError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const handleUpdateInput1 = (event) => {
    const { name, value } = event.target;
    setNewBench({
      ...NewBench,
      [name]: value
    });
    if (name === "status") {
      if (!value) {
        setStatusError(true);
      } else {
        setStatusError(false);
      }
    }
    if (name === "role") {
      if (!value) {
        setRoleError(true);
      } else {
        setRoleError(false);
      }
    }
  }
    
  let handleUpdatePrimarySkillsInput = (event) => {
    setNewBench({
      ...newBench,
      primarySkills: [event.target.value],
    });
  };
  const getBenchIdItem = ({ id }) => {
    BenchServices.get(id).then((res) => {
      setNewBench({ ...res.data.data });
    });
  };

  useEffect(() => {
    if (id) {
      getBenchIdItem({ id });
      //dispatch(getBenchId({id}))
    }
  }, [id, dispatch]);
  let validateform = () => {
    return;
  };

  let handleSubmitForm = (event) => {
    event.preventDefault();
    if (!newBench.name || !newBench.email) {
      alert("please fill form");
    } else {
      if (!id) {
        // console.log(newBench);
        dispatch(createBench(newBench));
        console.log(newBench);
        toast.success("created successfully", { autoClose: 700 });
        navigate("/benchlist");
        dispatch(getBench());
        event.stopPropagation();

        // setInterval(()=>{
        //   event.stopPropagation();
        // },1000)
        // event.stopPropagation();
      } else {
        //console.log(newBench)
        console.log(newBench);
        dispatch(updateBench(newBench));
        toast.success("updated successfully", { autoClose: 700 });
        //setInterval(()=>{
        navigate("/benchlist");
        dispatch(getBench());
        event.stopPropagation();

        // },2000)
      }
    }
  };
  return (
    <React.Fragment>
      {/* <div className="container-lg"> */}
      <MDBContainer breakpoint="lg">
        <div className="d-flex flex-column text-center justify-content-start align-items-center vh-100">
          <MDBCard className="mt-3 mb-5">
            <MDBCardHeader>
              <MDBCardTitle className="text-center">
                <h2>{id ? "Update" : "New"} Bench Employee</h2>
              </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
              {/* <pre>{JSON.stringify(newBench)}</pre> */}
              <MDBValidation
                className="row g-5 novalidate"
                onSubmit={handleSubmitForm}
              >
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your name"
                  // invalid
                  invalid={/^[a-zA-Z ]*$/.test(newBench.name)}
                >
                  <MDBInput
                    id="validationCustom01"
                    type="text"
                    name="name"
                    value={newBench.name}
                    onChange={handleUpdateInput}
                    required
                    label="Name*"
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your FL ID"
                  invalid
                >
                  <MDBInput
                    id="validationCustom02"
                    type="text"
                    name="emp_id"
                    value={newBench.emp_id}
                    onChange={handleUpdateInput}
                    required
                    label="FL ID"
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your email"
                  invalid
                >
                  <MDBInput
                    id="validationCustom03"
                    type="email"
                    required
                    name="email"
                    value={newBench.email}
                    onChange={handleUpdateInput}
                    label="Email*"
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your password"
                  invalid
                >
                  <MDBInput
                    id="validationCustom04"
                    type="password"
                    name="password"
                    value={newBench.password}
                    onChange={handleUpdateInput}
                    label="Password"
                    required
                  />
                </MDBValidationItem>

                {/* <div className="col-md-4">
                          <input
                             id="validationCustom05"
                             type="number"
                             // type="text"
                             min="0"
                             max="5"
                             name="totalWorkExp"
                             value={newBench.totalWorkExp}
                             onChange={handleUpdateInput}
                             label="Total Work Exp"
                             required
                          />
                        </div> */}

                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your total work exp"
                  invalid
                >
                  <MDBInput
                    id="validationCustom05"
                    type="number"
                    // type="text"
                    // min="1"
                    // max="5"
                    name="totalWorkExp"
                    value={newBench.totalWorkExp}
                    onChange={handleUpdateInput}
                    label="Total Work Exp"
                    required
                    // invalid={/^[0-9.]+$/.test(newBench.totalWorkExp)}
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your total exp in fission"
                  invalid
                >
                  <MDBInput
                    id="validationCustom06"
                    type="number"
                    name="totalExpinFission"
                    value={newBench.totalExpinFission}
                    onChange={handleUpdateInput}
                    label="Total Exp Fission"
                    required
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your skill"
                  invalid
                >
                  <MDBInput
                    id="validationCustom07"
                    type="text"
                    name="primarySkills"
                    value={newBench.primarySkills}
                    onChange={handleUpdatePrimarySkillsInput}
                    label="Skill Name"
                    required
                  />
                </MDBValidationItem>
                {/* <MDBValidationItem className="col-md-4" feedback='Please Enter your TotalExp' invalid>
              <MDBInput id='validationCustom08' type="number" required name="totalExp" value={newBench.primarySkills.totalExp} onChange={handleUpdatePrimarySkillsInput} label="TotalExp"/>
           </MDBValidationItem> */}

                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your reporting manager"
                  invalid
                >
                  <MDBInput
                    id="validationCustom09"
                    type="text"
                    name="reportingManager"
                    value={newBench.reportingManager}
                    onChange={handleUpdateInput}
                    label="Reporting Manager"
                    required
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your team lead"
                  invalid
                >
                  <MDBInput
                    id="validationCustom10"
                    type="text"
                    name="teamLead"
                    value={newBench.teamLead}
                    onChange={handleUpdateInput}
                    label="Team Lead"
                    required
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your project name"
                  invalid
                >
                  <MDBInput
                    id="validationCustom11"
                    type="text"
                    name="projectName"
                    value={newBench.projectName}
                    onChange={handleUpdateInput}
                    label="Project Name"
                    required
                  />
                </MDBValidationItem>
                {/* <MDBValidationItem
                  className="col-md-4"
                  feedback="Please Enter your Status"
                  invalid
                >
                  <MDBInput
                    id="validationCustom12"
                    type="text"
                    required
                    name="status"
                    value={newBench.status}
                    onChange={handleUpdateInput}
                    label="Status(Bench,BenchReserved,InProject)"
                  />
                </MDBValidationItem> */}
                <div className="col-md-4 d-flex justify-content-center ">
                  <div
                    className={`${statushasError ? "has-Error" : "selectBoxs"}`}
                  >
                    <select
                      id="validationCustom12"
                      className="input-boxes"
                      value={newBench.status}
                      onChange={handleUpdateInput1}
                      name="status"
                    >
                      <option className="initial-input">Select Status</option>
                      <option>Admin</option>
                      <option>Bench</option>
                      <option>BenchReserved</option>
                      <option>InProject</option>
                    </select>
                  </div>
                </div>

                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter date"
                  invalid
                >
                  <MDBInput
                    id="validationCustom13"
                    type="date"
                    name="bench_start_date"
                    data-date-format="DD MM YYYY"
                    value={moment(newBench.bench_start_date).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={handleUpdateInput}
                    label="Bench Start Date"
                    required
                  />
                </MDBValidationItem>
                <MDBValidationItem
                  className="col-md-4"
                  feedback="please enter your reporting manager email"
                  invalid
                >
                  <MDBInput
                    id="validationCustom14"
                    type="email"
                    name="reporting_manager_email"
                    value={newBench.reporting_manager_email}
                    onChange={handleUpdateInput}
                    label="Reporting Manager Email"
                    required
                  />
                </MDBValidationItem>
                <div className="col-md-4 d-flex justify-content-center ">
                  <div
                    className={`${statushasError ? "has-Error" : "selectBoxs"}`}
                  >
                    <select
                      id="validationCustom14"
                      className="input-boxes"
                      value={newBench.role}
                      onChange={handleUpdateInput1}
                      name="role"
                    >
                      <option className="initial-input">Select Role</option>
                      <option>admin</option>
                      <option>resource</option>
                    </select>
                  </div>
                </div>
                {/* <MDBValidationItem
                  className="col-md-4"
                  feedback="Please Enter your spreadsheetId"
                  invalid
                >
                  <MDBInput
                    id="validationCustom15"
                    type="text"
                    required
                    name="spreadsheetId"
                    value={newBench.spreadsheetId}
                    onChange={handleUpdateInput}
                    label="spreadsheetId"
                  />
                </MDBValidationItem> */}

                <div className="col-md-13">
                  <MDBTextArea
                    id="validationTextarea"
                    name="notes"
                    value={newBench.notes}
                    onChange={handleUpdateInput}
                    label="Notes"
                    required
                  />
                </div>
                <div className="col-12 text-center">
                  <MDBBtn className="m-2" type="submit">
                    {id ? "Update" : "Add"}
                  </MDBBtn>
                  <MDBBtn
                    className="m-2 btn btn-danger"
                    type="reset"
                    onClick={() => navigate("/benchlist")}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              </MDBValidation>
            </MDBCardBody>
          </MDBCard>
        </div>
      </MDBContainer>
      {/* <form className="mt-5">
          <div className="row mb-2 mt-2">
            <div className="col-4">
              <div className="group">
                <input type="text" id="form3Example1" required name="Name" />
                <label htmlFor="form3Example1">Name</label>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example2"
                  name="PrimarySkills"
                  required
                />
                <label htmlFor="form3Example2">Empp Id</label>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example3"
                  name="PrimarySkills"
                  required
                />
                <label htmlFor="form3Example3">Email</label>
              </div>
            </div>
          </div>

          <div className="row mb-2 mt-2">
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example4"
                  name="TotalWorkExp"
                  required
                />
                <label htmlFor="form3Example4">TotalWorkExp</label>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example5"
                  name="TotalExpinFission"
                  required
                />
                <label htmlFor="form3Example5">TotalExpinFission</label>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example6"
                  name="SkillName"
                  required
                />
                <label htmlFor="form3Example6">SkillName</label>
              </div>
            </div>
          </div>

          <div className="row mb-2 mt-2">
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example7"
                  name="totalExp"
                  required
                />
                <label htmlFor="form3Example7">TotalExp</label>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example8"
                  name="ReportingManager"
                  required
                />
                <label htmlFor="form3Example8">ReportingManager</label>
              </div>
            </div>
            <div className="col-4">
              <div className="group">
                <input
                  type="text"
                  id="form3Example9"
                  name="teamLead"
                  required
                />
                <label htmlFor="form3Example9">TeamLead</label>
              </div>
            </div>
          </div>
          <div className="row mb-2 mt-2">
            <div className="col-12">
              <div className="group">
                <input
                  type="text"
                  id="form3Example10"
                  name="notes"
                  required
                  className="w-100"
                />
                <label htmlFor="form3Example10">Notes</label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <MDBBtn className="m-2">Add</MDBBtn>
            <MDBBtn className="btn btn-danger m-2" onClick={() => navigate("/benchlist")}>Cancel</MDBBtn>
            <button type="submit" className="btn btn-primary  mb-4 newBenchEmployeeAddButton">
              Add
            </button>
            <button
              onClick={() => navigate("/benchlist")}
              className="btn btn-primary  mb-4 "
            >
              Cancel
            </button>
          </div>
        </form> */}
      {/* </div> */}
    </React.Fragment>
  );
};
export default NewBenchEmployee;
